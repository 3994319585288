<template>
  <div class="footprint" v-if="historyList.length">
    <div class="foot-del pa row">
      <div class="core row-end xs">
        <div class="del-box cursor row" @click="batchDel = !batchDel">
          <img src="@/assets/icon/icon-del.png" alt="" />
          {{ batchDel ? "取消批量" : "批量删除" }}
        </div>
      </div>
    </div>
    <div class="foot-list">
      <div class="foot-item" v-for="(item, index) in historyList" :key="index">
        <div class="item-title pr mb20">
          <span class="date-title lg bold lighter mr20"> {{ item.date }}</span>
          <span class="date-count xs lighter" v-if="!batchDel"
            >{{ item.count }}件宝贝</span
          >
          <span
            class="date-today xs lighter"
            v-if="batchDel"
            @click="delToday(index)"
            >删除当天</span
          >
        </div>
        <make-storeList
          width="236"
          height="382"
          ph="17"
          pv="17"
          titleSize="14"
          margin="6"
          :priceSize="20"
          :oldpriceShow="false"
          class="list mb10"
          :prolists="true"
          :goodsList="item.lists"
          :showDel="true"
          @delPro="
            (e) => {
              delHistory([e.history_id]);
            }
          "
        ></make-storeList>
      </div>
    </div>
  </div>
  <el-empty class="empty" description="你还没浏览过商品噢~" v-else></el-empty>
</template>
<script>
import MakeStoreList from "@/components/MakeStoreList";
export default {
  name: "",
  props: {},
  components: {
    "make-storeList": MakeStoreList,
  },
  data() {
    return {
      historyList: [], //获取历史记录
      batchDel: false, //批量删除
    };
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * @description 获取历史记录
     * @return void
     */
    getHistoryLists() {
      this.$api.getHistoryListsApi().then((res) => {
        this.historyList = res.data;
      });
    },
    /**
     * @description 删除历史记录
     * @param {Array} ids 待删除数组
     * @return void
     */
    async delHistory(ids) {
      let { code } = await this.$api.deleteHistoryByIdArrayApi({
        ids: ids,
      });
      if (code == 1) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getHistoryLists();
      }
    },
    /**
     * @description 批量删除逻辑
     * @param {Number} index 批量索引
     * @return void
     */
    delToday(index) {
      this.$confirm("您将批量删除历史记录", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let arr = this.historyList[index].lists.map((el) => {
          return el.history_id;
        });
        this.delHistory(arr);
      });
    },
  },
  created() {
    this.getHistoryLists();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='scss' scoped>
.foot-del {
  background-color: $-color-center;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  img {
    width: 30px;
    height: 30px;
  }
}
.foot-list {
  padding-top: 60px;
  .foot-item {
    border-bottom: $-solid-border;
    &:last-child {
      border-bottom: none;
    }
    ::v-deep .store-list {
      padding-bottom: 60px;
    }
    .item-title {
      height: 28px;
      line-height: 28px;
      span {
        height: 100%;
      }
      &::after {
        content: "";
        right: 0;
        width: 85%;
        height: 1px;
        background-color: $-color-border;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .date-today {
        background-color: $-color-normal;
        font-size: 12px;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        color: $-color-white;
        padding: 0 5px;
        cursor: pointer;
      }
    }
  }
}
.empty{
    padding: 80px 0;
}
</style>